module.exports =  {
  //  dev
    wsURL:'wss://esr9jgeytl.execute-api.us-east-1.amazonaws.com/dev',
    apiKey: "bfmA6KLuqD1mCT0XKifcF29nXCsTp4KW9g1PMY3c",
    baseUrl: "https://api.dev.webshareomatic.com",

    // //prod
    // wsURL: 'wss://oy3z1m527f.execute-api.us-east-1.amazonaws.com/prod',
    // baseUrl: "https://api.prod.webshareomatic.com",
    // apiKey: "0BkDYsfqvV6HMqnSZaGDQ6Ymbzc1K2xCkkAJo6E1" //prod

}